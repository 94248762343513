import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Renderer2,
  OnDestroy,
  OnInit,
  Inject,
} from '@angular/core';
import {
  Article,
  StrapiArticlePage,
  StrapiComponentTypeEnum,
  StrapiImage,
  StrapiImageClass,
} from '@moose/pwn-cms-model/lib';
import { EMPTY, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import * as moment from 'moment';

import { Logger } from '@core';
import { PageService } from '@shared/service/page/page.service';
import { ArticleService } from '@shared/service/article/article.service';
import { BreadcrumbService } from '@app/@shared/service/breadcrumb/breadcrumb.service';
import { environment } from '@env/environment';
import { ConfigurationService } from '@shared/service/configuration/configuration.service';
import { AdService } from '@app/@shared/service/ad/ad.service';
import { PdfService } from '@shared/service/pdf/pdf-service';
import { UserProductService } from '@app/@shared/service/user-product/user-product.service';
import { AuthenticationService } from '@app/@shared/service/authentication/authentication.service';
import { switchMap } from 'rxjs/operators';
import { PlatformService } from '@shared/service/ssr/platform.service';
import { UserService } from '@app/@shared/service/user/user.service';
import { MetaDataService } from '@shared/service/meta-data-service/meta-data.service';
import { MetaOgType } from '@shared/model/meta/meta-og-type.enum';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss', './article.component.mobile.scss'],
})
export class ArticleComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  readonly tenantId = environment.tenantId;
  articleId: string;
  activeUrl = '';
  viewLoaded = false;

  articlePageSubscription: Subscription;
  articleSubscription: Subscription;
  articlePage: StrapiArticlePage;
  article: Article;

  components: typeof StrapiComponentTypeEnum = StrapiComponentTypeEnum;

  logo: StrapiImageClass = null;
  configurationSubscription: Subscription = null;

  background: StrapiImageClass;
  backgroundSrc: string;
  public portalName: string;
  public portalUrl: string;
  public userEmail: string;
  public downloadDate: string;
  componentTypes: typeof StrapiComponentTypeEnum = StrapiComponentTypeEnum;

  logger = new Logger('article.component.ts');

  isLoggedIn$: Subscription;
  userEmail$: Subscription;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private DEFAULT_KEYWORDS =
    'portal psychologiczny, kształcenie psychoterapeuty, zawód psychologa, dziedziny psychologii, nurty psychoterapii';

  constructor(
    public pageService: PageService,
    private route: ActivatedRoute,
    private articleService: ArticleService,
    private configurationService: ConfigurationService,
    private router: Router,
    private location: Location,
    private adService: AdService,
    private breadcrumbService: BreadcrumbService,
    private pdfService: PdfService,
    private userProductService: UserProductService,
    private authenticationService: AuthenticationService,
    private metaDataService: MetaDataService,
    private platformService: PlatformService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.portalUrl = environment.serverUrl;
    this.portalName = this.portalUrl.substr(this.portalUrl.indexOf(':') + 3);
    if (this.authenticationService.isAuthenticated()) {
      this.userEmail$ = this.userService.getUser().subscribe((res) => {
        this.userEmail = res.email;
      });
    }

    this.downloadDate = moment().format('DD.MM.YYYY');

    this.activeUrl = environment.serverUrl + this.router.url;

    this.route.paramMap.subscribe((params) => {
      this.articleId = this.parseId(params.get('id'));
      this.articlePageSubscription = this.pageService
        .getArticlePage()
        .subscribe((articlePage: StrapiArticlePage) => {
          this.articlePage = articlePage;
          if (this.articlePage.seo) {
            this.metaDataService.setDataFromStrapiSeoComponent(
              this.articlePage
            );
          }
          this.articleSubscription = this.articleService
            .getArticle(this.articleId)
            .subscribe(
              (article: Article) => {
                const properUrl = '/artykul/' + article.slugId;
                if (!this.location.path().startsWith(properUrl)) {
                  this.location.go(properUrl);
                  this.metaDataService.createOrUpdateCanonical(properUrl);
                }

                this.article = article;
                if (!!this.article?.closedUntil) {
                  const currentDate = new Date();
                  const parsedDate = new Date(this.article.closedUntil);
                  this.authenticationService.setIsLogoutRedirect(
                    parsedDate > currentDate
                  );
                }
                this.adService.setAdContext(this.article.categories);
                this.breadcrumbService.article = article;
                let keywords = this.DEFAULT_KEYWORDS;
                const existingScript = this.document.head.querySelector(
                  'script[type="application/ld+json"]'
                );
                if (existingScript) {
                  this.renderer.removeChild(this.document.head, existingScript);
                }
                const plainText = this.getPlainText(this.article?.lead);
                const script = this.renderer.createElement('script');
                script.type = 'application/ld+json';
                script.text = `
                {
                  "@context": "https://schema.org",
                  "@type": "Article",   
                  "headline": "${this.article?.title}",
                  "description": "${plainText}",
                  "author": {
                    "@type": "Person",
                    "name": "${this.article?.authors.map((author) => {
                      return author?.name;
                    })}"
                  },
                  "datePublished": "${this.getDisplayDateSchema(
                    this.article?.displayDate
                  )}",
                  "image": "${this.getArticleImageUrl()}"
                }`;
                this.renderer.appendChild(this.document.head, script);
                if (this.article.tags) {
                  this.article.tags.forEach((t) => {
                    keywords = keywords + t.name + ', ';
                  });
                }
                this.article.categories.forEach((c) => {
                  keywords = keywords + c.name + ', ';
                });
                keywords = keywords.substring(0, keywords.length - 2);

                this.metaDataService.setData(
                  this.article.title,
                  this.article.lead,
                  this.getArticleImageUrl(),
                  this.activeUrl,
                  MetaOgType.Article,
                  keywords
                );
                this.metaDataService.createOrUpdateProductRetailerPartNo(
                  this.article.id
                );

                if (this.article.seo) {
                  this.metaDataService.setDataFromStrapiSeoComponent(
                    this.article
                  );
                }
              },
              (error) => {
                this.router.navigate(['/nie-znaleziono'], { replaceUrl: true });
              }
            );
        });
    });
    this.configurationSubscription = this.configurationService
      .getConfiguration()
      .subscribe((e) => {
        if (e != null) {
          this.logo = new StrapiImageClass(e.logo);
          if (e.backgroundImage) {
            this.background = new StrapiImageClass(e.backgroundImage);
            this.backgroundSrc = this.background.getPublicImageUrl(
              environment.serverUrl + '/strapi-proxy/' + this.tenantId
            );
          }
        }
      });
  }

  getPlainText(html: string): string {
    if (html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    }
  }

  getDisplayDateSchema(date: string): string {
    return moment(date).locale('pl').toISOString();
  }

  ngAfterViewInit(): void {
    this.addArticleToViewed();

    // unset min height for cls optimization
    setTimeout(() => {
      this.viewLoaded = true;
    }, 2000);
  }

  ngAfterViewChecked(): void {
    if (PlatformService.isBrowser) {
      this.renderEmbeddedMedia();
    }
  }

  renderEmbeddedMedia() {
    if (document) {
      document.querySelectorAll('oembed[url]').forEach((element) => {
        const anchor = document.createElement('a');

        anchor.setAttribute('href', element.getAttribute('url'));
        anchor.className = 'embedly-card';
        element.parentElement.parentElement.appendChild(anchor);
        element.remove();
      });
    }
  }

  downloadPdf() {
    try {
      this.pdfService.createPdf(this.articleId).subscribe((res: any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        if (document) {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = this.article.title + `.pdf`;
          link.click();
        }
      });
    } catch (error) {
      this.logger.error(error);
    }
  }

  getDisplayDate(date: string): string {
    return moment(date).locale('pl').format('LL');
  }

  addArticleToViewed(): void {
    this.isLoggedIn$ = this.authenticationService.isLoggedIn$
      .pipe(
        switchMap((res) => {
          if (res) {
            return this.userProductService.addArticleToViewed(this.articleId);
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe();
  }

  getArticleImageUrl(): string {
    if (environment.production) {
      return new StrapiImageClass({
        url: this.article.img.url,
        provider: 'aws-s3',
      } as StrapiImage).getPublicImageUrl(
        environment.serverUrl + '/strapi-proxy/' + this.tenantId
      );
    } else {
      return new StrapiImageClass({
        url: this.article.img.url,
        provider: 'local',
      } as StrapiImage).getPublicImageUrl(
        environment.serverUrl + '/strapi-proxy/' + this.tenantId
      );
    }
  }

  ngOnDestroy() {
    const existingScript = this.document.head.querySelector(
      'script[type="application/ld+json"]'
    );
    if (existingScript) {
      this.renderer.removeChild(this.document.head, existingScript);
    }
    this.authenticationService.setIsLogoutRedirect(false);
    this.userEmail$?.unsubscribe();
    this.articlePageSubscription?.unsubscribe();
    this.articleSubscription?.unsubscribe();
    this.isLoggedIn$?.unsubscribe();
    this.metaDataService?.setDefaultMetaData();
  }

  isBrowser(): boolean {
    return PlatformService.isBrowser;
  }

  private parseId(param: string) {
    return !!param ? param.substring(param.lastIndexOf('-') + 1) : null;
  }
}
